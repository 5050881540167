import { useAuthQuery } from '../../common/apiUtils/queryHelper';
import { contextIdConfig } from '../config';

const { url, queryKey } = contextIdConfig.get_contextids;

export const useGetListSitecoreContexts = () => {
  return useAuthQuery(queryKey, async (axiosInstance) => {
    const response = await axiosInstance?.get(url);
    return response?.data;
  });
};
