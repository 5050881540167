import { useFormik } from 'formik';
import { object, string } from 'yup';
import { useRouteMatch } from 'react-router-dom';
import { getContextIdsEditRoute } from '../routes';
import { useToast } from '@chakra-ui/react';
import { useCreateContextId } from '../api/useCreateContextId';

const initialValues = {
  contextId: '',
  name: '',
  description: '',
};

export type AddEditContextIdValues = typeof initialValues;

export const useAddContextId = ({
  onCloseDrawer,
}: {
  onCloseDrawer: () => void;
}) => {
  const toast = useToast();

  const onCreateContextIdSuccess = () => {
    toast({
      status: 'success',
      description: 'Context ID created successfully',
    });
    onCloseDrawer();
  };

  const {
    mutate: createSitecoreContext,
    isLoading: isCreateSitecoreContextLoading,
  } = useCreateContextId({
    onSuccess: onCreateContextIdSuccess,
  });

  const validationSchema = addEditContextIdValidationSchema();
  const isInEditMode = Boolean(useRouteMatch(getContextIdsEditRoute()));

  const {
    errors,
    values,
    handleChange,
    handleSubmit: formikHandleSubmit,
    setFieldValue,
    isValid,
  } = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    validateOnBlur: false,
    onSubmit: async (values) => {
      onSubmit(values);
    },
  });

  function onSubmit(values: any) {
    createSitecoreContext({
      name: values.name,
      description: values.description,
    });
  }

  const isLoading = isCreateSitecoreContextLoading;

  return {
    errors,
    values,
    handleChange,
    handleSubmit: formikHandleSubmit,
    setFieldValue,
    isValid,
    isLoading,
    isInEditMode,
  };
};

const addEditContextIdValidationSchema = () =>
  object({
    contextId: string().optional(),
    name: string().optional().max(80, 'Name must be less than 80 characters'),
    description: string()
      .optional()
      .max(160, 'Description must be less than 160 characters'),
  });
