import { Box, Image, ImageProps, Skeleton } from '@chakra-ui/react';
import { useState } from 'react';

export const ImageWithSkeleton = (props: ImageProps) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <Box
      position='relative'
      width={props.w}
      height={props.h}
      flexShrink={props.flexShrink}
    >
      <Skeleton
        isLoaded={isLoaded}
        width={props.w}
        height={props.h}
        borderRadius={props.borderRadius}
      >
        <Image
          fallbackSrc='https://dummyimage.com/28x28/fff/aaa'
          {...props}
          onLoad={() => setIsLoaded(true)}
          data-testid='image-with-skeleton'
        />
      </Skeleton>
    </Box>
  );
};
