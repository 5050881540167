// import { useRouteMatch } from 'react-router';
import { ContextIds } from './features/context-ids-home/ContextIds';

const ContextIdsContainer = () => {
  // const { path } = useRouteMatch();

  return <ContextIds />;
};

export default ContextIdsContainer;
