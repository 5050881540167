import { useQueryClient } from 'react-query';
import { useAuthMutation } from '../../common/apiUtils/queryHelper';
import { contextIdConfig } from '../config';

const {
  delete_contextid: { url, scope },
  get_contextids,
} = contextIdConfig;

export const useDeleteSitecoreContext = () => {
  const queryClient = useQueryClient();
  return useAuthMutation(
    (axiosInstance) =>
      ({ id }: { id: string }) =>
        axiosInstance?.delete(url(id)),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(get_contextids.queryKey);
      },
    },
    scope,
  );
};
