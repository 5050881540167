import { useMemo } from 'react';
import {
  generateContextsTableData,
  getTableOptions,
} from './helpers/contextIdsTableHelper';
import { DataTable } from '../../../../../common/components/DataTable';
import { getContextIdsDetailsRoute } from '../../../../routes';
import { history } from '../../../../../common/history/history';
import {
  SitecoreContextMappingType,
  SitecoreContextType,
} from '../../../../types/types';
import { useGetListSitecoreContexts } from '../../../../api/useGetListSitecoreContexts';
import EmptyState from '../../../../../common/components/EmptyState';
import { Flex } from '@chakra-ui/react';

// eslint-disable-next-line no-empty-function
const onChange = () => {};
const onClickRow = ({ sitecoreContextId }: SitecoreContextMappingType) =>
  history.push({
    pathname: getContextIdsDetailsRoute(sitecoreContextId),
    search: history.location.search,
  });

export const ContextIdsTable = ({
  contexts = [],
}: {
  contexts?: SitecoreContextType[];
}) => {
  const { data: fetchedContexts, isLoading: sitecoreContextsLoading } =
    useGetListSitecoreContexts();

  const tableData = useMemo(
    () => generateContextsTableData(fetchedContexts || contexts),
    [fetchedContexts, contexts],
  );
  const options = useMemo(() => getTableOptions(), []);

  return (
    <Flex>
      {!sitecoreContextsLoading && tableData.length === 0 ? (
        <EmptyState
          text='No context IDs created. Add your first context ID for your organization.'
          dataTestId='no-context-container'
        />
      ) : (
        <DataTable
          columns={options.columns}
          rowActions={options.rowActions}
          onChange={onChange}
          isLoading={sitecoreContextsLoading}
          data={tableData}
          theaderProps={{
            'data-behavior-analytics-id': 'contexts sorting',
          }}
          onClickRow={onClickRow}
          pagination='hidden'
          data-behavior-analytics-feature='contexts table'
        />
      )}
    </Flex>
  );
};
