import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Textarea,
} from '@chakra-ui/react';
import { T } from '@transifex/react';

interface Props extends FormControlProps {
  error?: string | undefined;
  isInvalid?: boolean;
  isRequired?: boolean;
  label: string;
  name: string;
  value?: string;
  onChange?: (e: any) => void;
  dataTestId?: string;
  onFocus?: () => void;
  isDisabled?: boolean;
  pt?: number;
  maxW?: string;
  helperText?: string;
  placeholder?: string;
  isTextarea?: boolean;
}

export const InputFormControl: React.FC<Props> = ({
  error,
  isInvalid,
  isRequired = false,
  isReadOnly = false,
  label,
  name,
  value = '',
  onChange,
  dataTestId = `input-element-${name}`,
  onFocus,
  isDisabled,
  pt = 0,
  maxW = 'md',
  helperText,
  placeholder = '',
  isTextarea = false,
}) => (
  <FormControl {...{ isInvalid, isRequired, pt, maxW }}>
    <FormLabel>
      <T _str={label} />
    </FormLabel>
    {isTextarea ? (
      <Textarea
        {...{
          name,
          value,
          onChange,
          onFocus,
          isDisabled,
          isReadOnly,
          placeholder,
        }}
      />
    ) : (
      <Input
        {...{
          name,
          value,
          onChange,
          onFocus,
          isDisabled,
          isReadOnly,
          placeholder,
        }}
        data-testid={dataTestId}
      />
    )}
    <FormErrorMessage>{error}</FormErrorMessage>
    {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
  </FormControl>
);
