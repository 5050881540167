import { hostnames } from '../common/config/envConfig';

export function getContextApiUrl() {
  const contextApiBaseUrl = {
    [hostnames.localhost]:
      'https://edge-platform-context-api-staging.sitecore-staging.cloud/api/context/user/v1',
    [hostnames.internal]:
      'https://edge-platform-context-api-staging.sitecore-staging.cloud/api/context/user/v1',
    [hostnames.dev]:
      'https://edge-platform-context-api-staging.sitecore-staging.cloud/api/context/user/v1',
    [hostnames.qa]:
      'https://edge-platform-context-api-staging.sitecore-staging.cloud/api/context/user/v1',
    [hostnames.staging]:
      'https://edge-platform-context-api-staging.sitecore-staging.cloud/api/context/user/v1',
    [hostnames.beta]:
      'https://edge-platform-context-api-beta.sitecorecloud.io/api/context/user/v1',
    [hostnames['pre-production']]:
      'https://edge-platform-context-api-beta.sitecorecloud.io/api/context/user/v1',
    [hostnames.production]:
      'https://edge-platform-context-api.sitecorecloud.io/api/context/user/v1',
  }[window.location.hostname];

  return contextApiBaseUrl || '';
}

const contextIdApiBaseUrl = getContextApiUrl();

//TODO: Add the correct scope for the contextIdConfig

export const contextIdConfig = {
  get_contextids: {
    url: `${contextIdApiBaseUrl}/contexts`,
    queryKey: ['contexts/list'] as const,
    scope: 'ep.usr.ctx:r',
  },
  create_contextid: {
    url: `${contextIdApiBaseUrl}/contexts`,
    scope: 'ep.usr.ctx:w',
  },
  delete_contextid: {
    url: (id: string) => `${contextIdApiBaseUrl}/contexts/${id}`,
    scope: 'ep.usr.ctx:w',
  },
};
