import {
  Flex,
  Heading,
  ButtonGroup,
  Button,
  Text,
  Box,
} from '@chakra-ui/react';
import { mdiPlus } from '@mdi/js';
import { T } from '@transifex/react';
import { PortalIcon } from 'features/common/components/PortalIcon';
import { history } from 'features/common/history/history';
import {
  contextIdsAddRoute,
  ContextIdsDrawerRoute,
  getContextIdsEditRoute,
  getContextIdsDeleteRoute,
} from 'features/context-ids/routes';
import { Feature } from 'lib/featureFlags/features';
import { ReactNode } from 'react';
import { ContextIdsTable } from './components/ContextIdsTable';
import DeleteContextIdModal from './components/DeleteContextIdModal';
import { CreateContextIdDrawer } from './components/create-contextid/CreateContextIdDrawer';

const Header = ({ children }: { children: ReactNode }) => (
  <Flex
    flexDirection={['column', 'column', 'row']}
    justifyContent='space-between'
    marginBottom={12}
    gap={[6, 6, 0]}
  >
    {children}
  </Flex>
);

const onClickAdd = () =>
  history.push({
    pathname: contextIdsAddRoute,
    search: history.location.search,
  });

export const ContextIds = () => (
  <>
    <Flex direction='column'>
      <Header>
        <Box>
          <Heading as='h1'>
            <T _str='Context IDs' />
          </Heading>
          <Text mt={5} maxW='3xl'>
            <T
              _str={
                "A Context ID is a unified identifier that enables your application to access your organization's resources. When you create a Context ID, you specify the resources that the Context ID will grant access to."
              }
            />
          </Text>
        </Box>
        <ButtonGroup>
          <Feature name='CONTEXT_MAPPING'>
            <Button
              variant='solid'
              onClick={onClickAdd}
              leftIcon={<PortalIcon path={mdiPlus} />}
              data-testid='create-context-id-toggle'
              data-behavior-analytics-id='Add context ID button - Context IDs page'
            >
              <T _str='Add context ID' />
            </Button>
          </Feature>
        </ButtonGroup>
      </Header>
      <ContextIdsTable />
    </Flex>
    <ContextIdsDrawerRoute
      exact
      path={contextIdsAddRoute}
      component={CreateContextIdDrawer}
    />
    <ContextIdsDrawerRoute
      exact
      path={getContextIdsEditRoute()}
      component={CreateContextIdDrawer}
    />
    <ContextIdsDrawerRoute
      exact
      path={getContextIdsDeleteRoute()}
      component={DeleteContextIdModal}
    />
  </>
);
