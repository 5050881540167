import {
  Drawer,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  useDisclosure,
  ButtonGroup,
  Button,
} from '@chakra-ui/react';
import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { CreateContextIdDrawerBody } from './CreateContextIdDrawerBody';
import { useAddContextId } from '../../../../hooks/useAddContextId';
import { contextIdsRoute } from 'features/context-ids/routes';

export const CreateContextIdDrawer = () => {
  const history = useHistory();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const onCloseDrawer = useCallback(() => {
    history.push({
      pathname: contextIdsRoute,
      search: history.location.search,
    });
    onClose();
  }, [history, onClose]);

  useEffect(() => {
    if (isOpen) return;
    onOpen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    handleSubmit,
    isLoading,
    values,
    errors,
    handleChange,
    isInEditMode,
    isValid,
  } = useAddContextId({ onCloseDrawer });

  return (
    <Drawer onClose={onCloseDrawer} isOpen={isOpen} size='lg'>
      <form
        onSubmit={handleSubmit}
        data-testid='add-edit-contextid-drawer-form'
      >
        <DrawerOverlay />
        <DrawerContent
          data-testid='add-edit-contextid-drawer-content'
          data-behavior-analytics-feature='Add edit context id drawer'
        >
          <DrawerHeader display='flex' alignItems='center'>
            {`${isInEditMode ? 'Edit context ID' : 'Add context ID'}`}
          </DrawerHeader>
          <CreateContextIdDrawerBody
            {...{
              errors,
              values,
              handleChange,
              isInEditMode,
            }}
          />
          <DrawerFooter justifyContent='flex-end'>
            <ButtonGroup>
              <Button
                data-testid='add-edit-contextid-drawer-cancel-btn'
                type='button'
                variant='ghost'
                onClick={onCloseDrawer}
                isDisabled={isLoading}
              >
                Cancel
              </Button>
              <Button
                type='submit'
                variant='solid'
                data-testid='add-edit-contextid-drawer-save-btn'
                isLoading={isLoading}
                isDisabled={!isValid}
              >
                Save
              </Button>
            </ButtonGroup>
          </DrawerFooter>
        </DrawerContent>
      </form>
    </Drawer>
  );
};
