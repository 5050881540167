import { Flex, Image, Text, AlertStatus } from '@chakra-ui/react';
import { WarningIcon } from '@chakra-ui/icons';
import { t } from '@transifex/native';
import { T } from '@transifex/react';
import { format, formatDuration, intervalToDuration } from 'date-fns';
import {
  ContextUserApplication,
  Entitlement,
  EntitlementsConnection,
} from 'gql/graphql';
import { round } from 'lodash';

const dateFormat = 'MMMM d y';
const shouldUseWorkaroundUntilBeFix = true;

export const getTableSchema = (
  productsIcons: ContextUserApplication[],
  entitlementUsageLimitExceeded: {
    subscriptionId: string;
    hasExceededLimit: boolean | undefined;
  }[],
) => [
  {
    id: 'product',
    Header: <Text marginStart={10}>Product</Text>,
    accessor: ({ sellableProductCode }: { sellableProductCode: string }) => {
      const { name, iconUrl } = shouldUseWorkaroundUntilBeFix
        ? iconByProduct[sellableProductCode as keyof typeof iconByProduct] ?? {
            name: '',
            iconUrl: '',
          }
        : getProductIcon({
            productCode: sellableProductCode,
            productsIcons: productsIcons ?? [],
          });

      return (
        <Flex alignItems='center' gap={5}>
          <Image src={iconUrl} alt={`${name}-icon`} />
          <Text fontWeight='bold'>
            <T _str={name} />
          </Text>
        </Flex>
      );
    },
  },
  {
    id: 'subscription',
    Header: t('Subscription'),
    accessor: ({ editionType }: { editionType: string }) => editionType,
  },

  {
    id: 'contractPeriod',
    Header: t('Contract period'),
    accessor: ({
      contractStart,
      contractEnd,
      subscriptionId,
    }: {
      contractStart: Date;
      contractEnd: Date;
      entitlements: EntitlementsConnection;
      subscriptionId: string;
    }) => {
      const formattedStartDate = format(new Date(contractStart), dateFormat);
      const formattedEndDate = format(new Date(contractEnd), dateFormat);

      const shouldShowWarningIcon = entitlementUsageLimitExceeded.some(
        (item) =>
          item.subscriptionId === subscriptionId && item.hasExceededLimit,
      );

      return (
        <Flex alignItems='center' justifyContent='space-between'>
          <Text>
            <T _str={`${formattedStartDate} - ${formattedEndDate}`} />
          </Text>
          {shouldShowWarningIcon && <WarningIcon color='warning.500' />}
        </Flex>
      );
    },
    disableSortBy: true,
  },
];

const getProductIcon = ({
  productCode,
  productsIcons,
}: {
  productCode: string;
  productsIcons: ContextUserApplication[];
}) => {
  const product = productsIcons.find(
    (productIcons) => productCode == productIcons.productCode.toLowerCase(),
  );

  const name = product?.appearance?.web.actions?.nodes?.[0]?.name ?? '';
  const iconUrl = product?.appearance?.web.actions?.nodes?.[0]?.icon.src ?? '';

  return { name, iconUrl };
};

export const iconByProduct = {
  search: {
    iconUrl:
      'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/mark-search',
    name: 'Search',
  },
  discover: {
    iconUrl:
      'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/mark-discover',
    name: 'Discover',
  },
  personalize: {
    iconUrl:
      'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/mark-personalize',
    name: 'Personalize',
  },
  headlesscms: {
    iconUrl:
      'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/mark-content_hub_one',
    name: 'Content Hub ONE',
  },
  xmcloud: {
    iconUrl:
      'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/mark-xm_cloud',
    name: 'XM Cloud',
  },
  cdp: {
    iconUrl:
      'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/mark-cdp',
    name: 'CDP',
  },
  ordercloud: {
    iconUrl:
      'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/mark-ordercloud',
    name: 'OrderCloud',
  },
  send: {
    iconUrl:
      'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/mark-send',
    name: 'Send',
  },
  dam: {
    iconUrl:
      'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/mark-content_hub_dam',
    name: 'Content Hub DAM',
  },
  contentoperations: {
    iconUrl:
      'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/mark-content_hub_ops',
    name: 'Content Hub Operations',
  },
  connect: {
    iconUrl:
      'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/mark-connect',
    name: 'Connect',
  },
  contenthubdam: {
    iconUrl:
      'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/mark-content_hub_dam',
    name: 'Content Hub DAM',
  },
  ai: {
    iconUrl:
      'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/mark-stream',
    name: 'Stream',
  },
  contentservices: {
    iconUrl:
      'https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/mark-xm_cloud_content',
    name: 'XM Cloud Content',
  },
} as Record<string, any>;

export const calculateUsage = (entitlement: Entitlement) => {
  let totalUsage = 0;

  entitlement.consumption?.currentPeriod.usages.forEach((usage) => {
    totalUsage = totalUsage + usage.value;
  });
  return totalUsage;
};

type OverageEntitlements = {
  name: string;
  alertStatus?: AlertStatus;
  quotaStatus?: string;
};

export const getEntitlementData = (entitlement: Entitlement) => {
  const { name, limitType, quantity } = entitlement;
  const used = calculateUsage(entitlement);
  const { percentUsed } = getPercentUsed(used, entitlement.quantity);

  if (limitType === 'UNLIMITED' && [0, null].includes(quantity))
    return {
      name,
      used,
      percentUsed: Infinity,
      textColor: '',
      colorScheme: 'neutral',
    };

  if (isFinite(percentUsed))
    return {
      name,
      used,
      percentUsed,
      textColor:
        percentUsed > 100 ? 'danger' : percentUsed === 100 ? 'warning' : '',
      colorScheme:
        percentUsed > 100
          ? 'danger'
          : percentUsed === 100
          ? 'warning'
          : 'neutral',
    };
};

export const getPercentUsed = (currentUsage: number, quantity: number) => {
  const used = currentUsage / quantity;

  const percentUsed = round(used * 100, 1);
  return {
    used,
    percentUsed: !isFinite(used) ? used : percentUsed,
  };
};

export const getOverageEntitlements = ({
  entitlements,
}: {
  entitlements: Entitlement[];
}) => {
  return entitlements
    ?.filter((entitlement: Entitlement) => {
      const currentUsage = calculateUsage(entitlement);

      // If the entitlement is unlimited, we don't want to show any alerts
      const isFiniteUsage = isFinite(currentUsage / entitlement.quantity);
      if (!isFiniteUsage) return false;

      const isOverUsageOrAtMax = currentUsage >= entitlement.quantity;
      return !!isOverUsageOrAtMax;
    })
    .map((entitlement): OverageEntitlements => {
      const { name, percentUsed } = getEntitlementData(entitlement) as
        | {
            name: string;
            used: number;
            percentUsed: number;
            colorScheme: string;
            textColor?: undefined;
          }
        | {
            name: string;
            used: number;
            percentUsed: number;
            textColor: string;
            colorScheme: string;
          };

      return {
        name,
        alertStatus: percentUsed > 100 ? 'error' : 'warning',
        quotaStatus: percentUsed > 100 ? 'exceeding' : 'reached',
      };
    });
};

export const getRemainingTime = (start: Date, end: Date) => {
  const interval = intervalToDuration({
    start: start,
    end: end,
  });

  return formatDuration(interval, { format: ['months', 'days'] });
};
