import { ApmRoute } from '@elastic/apm-rum-react';
import * as React from 'react';
import { Redirect, match } from 'react-router';
import { useFeatures } from 'lib/featureFlags/features';
import { adminPath, contextIdsPath } from '../../admin/components/admin-paths';

interface Props {
  component: React.ComponentType<any>;
  path: string;
  exact?: boolean;
  match?: match<{ [p: string]: string | undefined }>;
}
export const ContextIdsDrawerRoute = ({
  component: Component,
  ...rest
}: Props) => {
  const { CONTEXT_MAPPING: isCtxMappingEnabled } = useFeatures();
  return (
    <ApmRoute
      {...rest}
      render={({ match, ...restProps }) => {
        return !isCtxMappingEnabled ? (
          <div>
            <Redirect to='/no-access' />
          </div>
        ) : (
          <Component match={match} {...restProps} />
        );
      }}
    />
  );
};

export const contextIdsRoute = `${adminPath}${contextIdsPath}`;
export const contextIdsAddRoute = `${contextIdsRoute}/create`;
export const getContextIdsEditRoute = (sitecoreContextId?: string) =>
  `${contextIdsRoute}/${
    sitecoreContextId ? sitecoreContextId : ':sitecoreContextId'
  }/edit`;
export const getContextIdsDeleteRoute = (sitecoreContextId?: string) =>
  `${contextIdsRoute}/${
    sitecoreContextId ? sitecoreContextId : ':sitecoreContextId'
  }/delete`;
export const getContextIdsDetailsRoute = (sitecoreContextId?: string) =>
  `${contextIdsRoute}/${
    sitecoreContextId ? sitecoreContextId : ':sitecoreContextId'
  }/details`;
