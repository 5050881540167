import {
  Checkbox,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  IconButton,
} from '@chakra-ui/react';
import React from 'react';
import {
  Hooks,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import {
  DataTableColumn,
  DataTableProps,
  DataTableRowAction,
  DataTableRowActionCallback,
} from './model';
import { mdiArrowDown, mdiArrowUp, mdiDotsHorizontal } from '@mdi/js';
import { Pagination } from '../Pagination/Pagination';
import { PortalIcon } from '../PortalIcon';

/**
 * Internal function for generating the rowAction menu column
 */

const buildRowActionHook = (
  rowActions: DataTableRowAction[] | DataTableRowActionCallback,
) => {
  return (hooks: Hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        id: 'rowActions',
        width: '1%',
        disableSortBy: true,
        Cell: ({ row }) => {
          const realRowActions =
            typeof rowActions === 'function' ? rowActions(row) : rowActions;
          return (
            <Menu placement='bottom-end'>
              <MenuButton
                onClick={(event) => event.stopPropagation()}
                as={IconButton}
                size='sm'
                variant='ghost'
                icon={<PortalIcon path={mdiDotsHorizontal} />}
              />
              <MenuList>
                {realRowActions.map((ra) => (
                  <MenuItem
                    key={ra.id}
                    onClick={(e) => {
                      e.stopPropagation();
                      ra.onClick(e, row);
                    }}
                    {...(ra['data-behavior-analytics-id'] && {
                      'data-behavior-analytics-id':
                        ra['data-behavior-analytics-id'],
                    })}
                  >
                    {ra.label}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          );
        },
      },
    ]);
  };
};

/**
 * Internal function for generating the checkbox selection column
 */
const selectableColumnHook = (hooks: Hooks) => {
  hooks.visibleColumns.push((columns) => [
    {
      id: 'selection',
      width: '1%',
      // The header can use the table's getToggleAllRowsSelectedProps method
      // to render a checkbox
      Header: ({ getToggleAllRowsSelectedProps }: any) => {
        const checkboxProps = getToggleAllRowsSelectedProps();
        return (
          <Checkbox
            isChecked={checkboxProps.checked}
            isIndeterminate={checkboxProps.indeterminate}
            {...checkboxProps}
          />
        );
      },
      // The cell can use the individual row's getToggleRowSelectedProps method
      // to the render a checkbox
      Cell: ({ row }: any) => {
        const checkboxProps = row.getToggleRowSelectedProps();
        return (
          <Checkbox
            isChecked={checkboxProps.checked}
            isIndeterminate={checkboxProps.indeterminate}
            {...checkboxProps}
          />
        );
      },
    } as DataTableColumn,
    ...columns,
  ]);
};

const emptyHook = () => {
  return;
};

/**
 * Sitecore UI DataTable component
 */
export const DataTable = ({
  data,
  height,
  width,
  minWidth,
  maxWidth,
  gutterBottom = true,
  stickyHeader,
  columns,
  isLoading,
  totalItems = 0,
  size,
  variant,
  selectable,
  pagination,
  page,
  pageSize = 10,
  sortBy,
  rowActions,
  onClickRow,
  onChange,
  autoSort = true,
  theaderProps,
  paginationProps,
  alignPagination = 'left',
  'data-testid': dataTestId,
  'data-behavior-analytics-feature': dataBehaviorAnalyticsFeature,
}: DataTableProps) => {
  const totalPages = React.useMemo(() => {
    return Math.ceil(totalItems / pageSize);
  }, [totalItems, pageSize]);

  const initialState = React.useMemo(() => {
    if (!page) return;
    return {
      pageIndex: page - 1,
      sortBy,
    };
  }, [page, sortBy]);

  const {
    // @ts-ignore
    state: { pageIndex, sortBy: sb },
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    // @ts-ignore
    page: p,
    // @ts-ignore
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      // @ts-ignore
      initialState,
      manualSortBy: autoSort,
      manualPagination: true,
      pageCount: totalPages,
    },
    useSortBy,
    usePagination,
    selectable ? useRowSelect : emptyHook,
    selectable ? selectableColumnHook : emptyHook,
    rowActions ? buildRowActionHook(rowActions) : emptyHook,
  );

  React.useEffect(() => {
    if (!onChange || typeof onChange !== 'function') return;

    const newPage = pageIndex + 1;

    if ((!page || page === newPage) && (!sortBy || sb === sortBy)) return;
    onChange({
      page: newPage,
      sortBy: sb,
    });
  }, [pageIndex, page, sb, sortBy, onChange]);

  const loadingRows = React.useMemo(() => {
    return Array.apply(null, Array(pageSize)).map((r, i) => (
      <Tr key={i}>
        {headerGroups[0].headers.map((c, ci) => (
          <Td key={`${i}_${ci}`} style={{ width: c.width }}>
            <Skeleton
              w='full'
              h={rowActions && rowActions.length ? '8' : '5'}
            />
          </Td>
        ))}
      </Tr>
    ));
  }, [pageSize, headerGroups, rowActions]);

  const tHeadProps = React.useMemo(() => {
    return stickyHeader
      ? {
          zIndex: 2,
          position: 'sticky' as any,
          top: 0,
          backgroundColor: 'white',
          ...theaderProps,
        }
      : { ...theaderProps };
  }, [stickyHeader, theaderProps]);

  const isClickableRow = !!onClickRow;

  return (
    <React.Fragment>
      <Table
        {...getTableProps()}
        fontSize='md'
        size={size}
        variant={variant}
        mb={gutterBottom ? 6 : 0}
        height={height}
        width={width}
        minWidth={minWidth}
        maxWidth={maxWidth}
        data-testid={dataTestId}
        data-behavior-analytics-feature={dataBehaviorAnalyticsFeature}
      >
        <Thead {...tHeadProps}>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => {
                return (
                  <Th
                    style={{ width: column.width }}
                    {...column.getHeaderProps(
                      column.getSortByToggleProps({
                        title: column.disableSortBy
                          ? `${column.Header}`
                          : `Sort by ${column.Header}`,
                      }),
                    )}
                    isNumeric={column.isNumeric}
                  >
                    <Flex
                      align='center'
                      justify={column.isNumeric ? 'end' : 'start'}
                    >
                      {column.render('Header')}
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <PortalIcon
                            path={mdiArrowDown}
                            boxSize='4'
                            aria-label='sorted descending'
                          />
                        ) : (
                          <PortalIcon
                            path={mdiArrowUp}
                            boxSize='4'
                            aria-label='sorted ascending'
                          />
                        )
                      ) : null}
                    </Flex>
                  </Th>
                );
              })}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {isLoading
            ? loadingRows
            : p.map((row: any) => {
                prepareRow(row);
                return (
                  <Tr
                    {...row.getRowProps()}
                    onClick={() => onClickRow?.(row.original)}
                    style={{ cursor: isClickableRow ? 'pointer' : 'default' }}
                    {...(isClickableRow && { _hover: { bg: 'gray.50' } })}
                  >
                    {row.cells.map((cell: any) => (
                      <Td
                        {...cell.getCellProps()}
                        style={{ width: cell.column.width }}
                        isNumeric={cell.column.isNumeric}
                      >
                        {cell.render('Cell')}
                      </Td>
                    ))}
                  </Tr>
                );
              })}
        </Tbody>
      </Table>

      {pagination !== 'hidden' && (
        <Flex justify={alignPagination} data-testid='dataTable_pagination'>
          <Pagination
            variant={pagination}
            page={pageIndex + 1}
            totalItems={totalItems}
            pageSize={pageSize}
            onChange={(p: number) => {
              gotoPage(p - 1);
            }}
            {...paginationProps}
          />
        </Flex>
      )}
    </React.Fragment>
  );
};
